/*

Tooplate 2120 Ben Resume

https://www.tooplate.com/view/2120-ben-resume

*/

  @font-face {
      font-family: 'Open Sans';
      src: url('../localfonts/OpenSans-Regular.woff2') format('woff2'),
          url('../localfonts/OpenSans-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Open Sans';
      src: url('../localfonts/OpenSans-Light.woff2') format('woff2'),
          url('../localfonts/OpenSans-Light.woff') format('woff');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Open Sans';
      src: url('../localfonts/OpenSans-Bold.woff2') format('woff2'),
          url('../localfonts/OpenSans-Bold.woff') format('woff');
      font-weight: bold;
      font-style: normal;
  }

  :root {
    --primary-color:        #ffd500;
    --white-color:          #ffffff;
    --dark-color:           #010101;
    --menu-bg-color:        #212529;

    --gray-color:           #909090;
    --p-color:              #7d7a7a;

    --base-font-family:     "Open Sans", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-weight-bold:     600;
    --font-weight-normal:   400;
    --font-weight-light:    300;

    --h1-font-size:         56px;
    --h2-font-size:         48px;
    --h3-font-size:         38px;
    --h4-font-size:         34px;
    --h5-font-size:         30px;
    --h6-font-size:         24px;
    --p-font-size:          18px;
    --base-font-size:       16px;
    --button-font-size:     20px;

    --border-radius-large:  100%;
    --border-radius-medium: 80px;
    --border-radius-small:  40px;
  }


  body {
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
  }


  /*---------------------------------------
     TYPOGRAPHY              
  -----------------------------------------*/

  h1 {
    font-size: var(--h1-font-size);
  }

  h1,
  h3 {
    font-weight: var(--font-weight-light);
  }

  h2 {
    font-size: var(--h2-font-size);
  }

  h3 {
    font-size: var(--h3-font-size);
  }

  h4 {
    font-size: var(--h4-font-size);
  }

  h5 {
    font-size: var(--h5-font-size);
  }

  h6 {
    font-weight: var(--font-weight-bold);
    font-size: var(--h6-font-size);
  }

  p {
    color: black;
    font-size: var(--p-font-size);
    font-weight: var(--font-weight-light);
    letter-spacing: 0.3px;
    line-height: 2em;
  }

  .section-padding {
    padding: 6rem 0;
  }

   .section-padding .applications{
  padding: 0 !important;
}

  .custom-icon {
    font-size: 12px;
    position: relative;
    bottom: 3px;
    left: 5px;
    transform: rotate(-25deg);
  }


  /* CUSTOM BUTTON */
  .custom-btn {
    background: var(--dark-color);
    border: none;
    border-radius: var(--border-radius-medium);
    color: var(--white-color);
    letter-spacing: 0.2px;
    padding: 6px 36px 10px 36px;
    font-size: var(--button-font-size);
    white-space: nowrap;
  }

  .custom-btn:hover,
  .custom-btn:focus {
    color: var(--dark-color);
    background: var(--primary-color);
  }


  /*---------------------------------------
     GENERAL               
  -----------------------------------------*/

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  a {
    color: var(--dark-color);
    text-decoration: none;
    transition: all 0.4s linear;
  }

  a:hover  {
    color: var(--dark-color);
    text-decoration: none;
  }


  /*---------------------------------------
     MENU                
  -----------------------------------------*/

  .navbar {
    background: transparent;
    padding: 0.3rem 1rem;
    transition: all 300ms ease-in-out;
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
  }

  .navbar.scroll {
    background: var(--menu-bg-color);
    padding: 0.5rem 1rem;
    position: fixed;
    opacity: 1;
  }

  .navbar-brand {
    color: var(--white-color);
    font-weight: var(--font-weight-light);
    font-size: var(--h4-font-size);
  }

  .navbar .custom-btn {
    background: var(--primary-color);
    color: var(--dark-color);
  }

  .contact-form #submit-button:hover,
  .navbar .custom-btn:hover {
    background: var(--dark-color);
    color: var(--white-color);
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin: 0 1.5rem;
    padding: 0;
    font-weight: 600;
  }

  .nav-item .nav-link.active, 
  .nav-item .nav-link:hover {
    color: var(--gray-color);
  }

  .nav-item .nav-link.active, 
  .nav-item .nav-link:hover {
    border-bottom-color: var(--primary-color);
  }

  .nav-link {
    color: var(--gray-color);
    font-weight: var(--font-weight-light);
    font-size: var(--base-font-size);
    border-bottom: 1px solid transparent;
  }

  .navbar-toggler {
    border: 0;
    padding: 0;
    cursor: pointer;
    margin: 0 10px 0 0;
    width: 30px;
    height: 35px;
    outline: none;
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background: transparent;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before,
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::after {
    transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
    transition: top 300ms 50ms ease, transform 300ms 350ms ease;
    transition: top 300ms 50ms ease, transform 300ms 350ms ease, -webkit-transform 300ms 350ms ease;
    top: 0;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before {
    transform: rotate(45deg);
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::after {
    transform: rotate(-45deg);
  }


  .navbar-toggler .navbar-toggler-icon {
    background: var(--white-color);
    transition: background 10ms 300ms ease;
    display: block;
    width: 30px;
    height: 2px;
    position: relative;
  }

  .navbar-toggler .navbar-toggler-icon::before,
  .navbar-toggler .navbar-toggler-icon::after {
    transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
    transition: top 300ms 350ms ease, transform 300ms 50ms ease;
    transition: top 300ms 350ms ease, transform 300ms 50ms ease, -webkit-transform 300ms 50ms ease;
    position: absolute;
    right: 0;
    left: 0;
    background: var(--white-color);
    width: 30px;
    height: 2px;
    content: '';
  }

  .navbar-toggler .navbar-toggler-icon::before {
    top: -8px;
  }

  .navbar-toggler .navbar-toggler-icon::after {
    top: 8px;
  }



  /*---------------------------------------
     HERO                
  -----------------------------------------*/

  .hero-title,
  .email-link {
    background: var(--dark-color);
    border-radius: var(--border-radius-medium);
    color: var(--white-color);
    display: inline-block;
    margin: 0.5rem 0;
    padding: 0.5rem 2rem 1.5rem 2rem;
	font-size: 2.2em;
  }

  .email-link {
    background: var(--primary-color);
    color: var(--dark-color);
    font-size: var(--h4-font-size);
    padding: 0.5rem 2rem 1rem 2rem;
  }

  .email-link:hover {
    background: var(--dark-color);
    color: var(--white-color);
  }


  /*---------------------------------------
     ABOUT                
  -----------------------------------------*/
  
  .about-third {
	  margin: 2.2em -0.8em;
  }

  .about-image {
    border-radius: var(--border-radius-small);
  }

  .profile-list li {
    margin: 0.3rem 0;
  }

  .profile-list strong {
    display: inline-block;
    width: 30%;
    margin-right: 1rem;
  }


  /*---------------------------------------
     FAQ                 
  -----------------------------------------*/

  .faq .card-header {
    padding: 0;
  }

  .faq .btn-link {
    color: var(--dark-color);
    font-size: var(--h6-font-size);
    text-align: left;
    display: block;
    width: 100%;
    padding: 1rem 2rem;
  }

  .faq .btn-link:focus, 
  .faq .btn-link:hover {
    color: var(--dark-color);
    text-decoration: none;
  }

  .faq .card-body {
    padding: 2rem;
  }

  .faq-info-text {
    font-size: var(--base-font-size);
    display: block;
    margin: 1.5rem 2rem 0 2rem;
  }


  /*---------------------------------------
     TESTIMONIALS               
  -----------------------------------------*/

  .testimonials {
    background: #f9f9f9;
  }

  .testimonials-image {
    margin: 0 1rem 1rem 1rem;
  }

  .testimonials-image img {
    border-radius: var(--border-radius-medium);
  }

  .testimonials-info {
    width: 100%;
  }

  .testimonials .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 2rem;
  }

  .testimonials .owl-theme .owl-dots .owl-dot {
    outline: none;
  }

  .testimonials .owl-theme .owl-dots .owl-dot span {
    width: 30px;
    height: 3px;
    margin: 5px;
  }

  .testimonials .owl-theme .owl-dots .owl-dot.active span, 
  .testimonials .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--primary-color);
  }


  /*---------------------------------------
     CONTACT               
  -----------------------------------------*/
  
  .contact-form .form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(142, 140, 140, 0.35);
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    outline: none;
    color: var(--dark-color);
    font-weight: var(--font-weight-light);
    padding-left: 0;
    cursor: text;
  }

  .contact-form input {
    height: calc(2.25rem + 10px);
  }

  .contact-form .form-group {
    margin: 0 0 1rem;
    position: relative;
  }

  .contact-form .form-control,
  .webform-label {
    transition: all 0.4s;
    touch-action: manipulation;
  }

  .webform-label {
    cursor: text;
    font-size: 12px;
    font-weight: var(--font-weight-light);
    margin-bottom: 5px;
  }

  .contact-form .form-control:placeholder-shown + .webform-label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
  }

  .contact-form ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
    color: var(--dark-color);
  }

  .contact-form ::-moz-placeholder {
    opacity: 0;
    transition: inherit;
    color: var(--dark-color);
  }

  .contact-form .form-control:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  .contact-form .form-control:focus::-moz-placeholder {
    opacity: 1;
  }

  .contact-form .form-control:not(:placeholder-shown) + .webform-label,
  .contact-form .form-control:focus + .webform-label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    color: var(--gray-color);
  }

  .contact-form #submit-button {
    background: var(--primary-color);
    border: none;
    border-radius: var(--border-radius-medium);
    color: var(--dark-color);
    cursor: pointer;
    font-size: var(--p-font-size);
    line-height: 0;
    margin-top: 2.5rem;
    padding: 1.5rem 1.8rem 1.8rem 1.8rem;
    white-space: nowrap;
    max-width: 190px;
  }

  .copyright-text {
    font-size: var(--base-font-size);
  }

.foto-flex{
  display: flex;
  justify-content: center;
}


  /*---------------------------------------
     SOCIAL LINKS              
  -----------------------------------------*/

  .social-links {
    margin: 40px 0;
    padding: 0;
  }

  .social-links li {
    display: inline-block;
    list-style: none;
  }

  .social-links a {
    display: inline-block;
    color: var(--p-color);
    font-size: var(--h6-font-size);
    margin: 5px 5px 5px 0;
  }

 .hidden-text {
    display: none;
  }
  
  .social-links a:hover {
    color: red;
  }

.tgm-bot, .wsa-bot, .mail-bot{
  font-weight: 600;
}
  
.tgm-bot:hover{
  color:blue;
}
.wsa-bot:hover{
  color:forestgreen;
}

.mail-bot:hover{
  color: orangered;
}

.fa-headhunter{
    background: #7d7a7a;
    color: white !important;
    font-weight: 600;
    border-radius: 2px;
    font-size: 22px !important;
    padding: 0 2px;
}

.fa-headhunter:hover{
  color: red;
  background: red;
}


  /*---------------------------------------
     RESPONSIVE STYLES              
  -----------------------------------------*/

  @media screen and (max-width: 1200px) {
    h1 {
        font-size: 42px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 32px;
    }

    h4 {
        font-size: 28px;
    }

    .email-link {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 992px) {

    .hero-title {
      font-size: 1.8em;
      padding-bottom: 1rem;
    }

    .email-link {
      font-size: 22px;
      padding: 0.5rem 2rem 0.7rem 2rem;
    }
  }

  @media screen and (max-width: 991px) {
    .navbar-nav {
      margin: 1rem 0;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      display: inline-block;
      margin: 5px 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    body{
      width: 100%;
    }

    .hero-text {
      position: relative;
      top: 1.5rem;
    }

    .profile-list li{
      display: flex;
    }

   /* .quote-app{
      display: none !important;
    }*/
  }

  @media screen and (max-width: 480px) {

    .hero-title {
      font-size: 26px;
    }

    .email-link {
      font-size: 20px;
      padding: 0.3rem 2rem 0.6rem 2rem;
    }
  }